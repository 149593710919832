import React, { Component } from 'react';
import { arrayOf, func, node, number, shape, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';

import { OutsideClickHandler } from '../../../components';

import PopupOpenerButton from '../PopupOpenerButton/PopupOpenerButton';
import GuestFilterForm from './GuestFilterForm/GuestFilterForm';
import css from './GuestsFilterPlain.module.css';
import IconPlus from '../IconPlus/IconPlus';

const KEY_CODE_ESCAPE = 27;
const RADIX = 10;

const getPriceQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'price';
};

// Parse value, which should look like "0,1000"
const parse = priceRange => {
  const [minPrice, maxPrice] = !!priceRange
    ? priceRange.split(',').map(v => Number.parseInt(v, RADIX))
    : [];
  // Note: we compare to null, because 0 as minPrice is falsy in comparisons.
  return !!priceRange && minPrice != null && maxPrice != null ? { minPrice, maxPrice } : null;
};

// Format value, which should look like { minPrice, maxPrice }
const format = (range, queryParamName) => {
  const { minPrice, maxPrice } = range || {};
  // Note: we compare to null, because 0 as minPrice is falsy in comparisons.
  const value = minPrice != null && maxPrice != null ? `${minPrice},${maxPrice}` : null;
  return { [queryParamName]: value };
};

class GuestsFilterPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.filter = null;
    this.filterContent = null;
    this.isFormOpen = true;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  handleSubmit(values) {
    const { onSubmit, queryParamNames } = this.props;
    this.setState({ isOpen: false });
    const priceQueryParamName = getPriceQueryParamName(queryParamNames);
    if (values.maxPrice == 15) {
      onSubmit({ guests: null });
      return;
    }
    onSubmit(format(values, priceQueryParamName));
  }

  handleClear() {
    const { onSubmit } = this.props;
    onSubmit({ guests: null });
  }

  handleCancel() {
    const { onSubmit, initialValues } = this.props;
    this.setState({ isOpen: false });
    onSubmit(initialValues);
  }

  handleBlur() {
    this.setState({ isOpen: false });
  }

  handleKeyDown(e) {
    if (e.keyCode === KEY_CODE_ESCAPE) {
      this.toggleOpen(false);
    }
  }

  toggleOpen(enforcedState) {
    if (enforcedState) {
      this.setState({ isOpen: enforcedState });
    } else {
      this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };

      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }
  toggleIsOpen() {
    this.setState(prevState => ({ isFormOpen: !prevState.isFormOpen }));
  }
  render() {
    const {
      rootClassName,
      className,
      id,
      label,
      queryParamNames,
      initialValues,
      min,
      max,
      step,
      intl,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const priceQueryParam = getPriceQueryParamName(queryParamNames);
    const initialPrice =
      initialValues && initialValues[priceQueryParam] ? parse(initialValues[priceQueryParam]) : {};
    const { minPrice, maxPrice } = initialPrice || {};

    const hasValue = value => value != null;
    const hasInitialValues = initialValues && hasValue(minPrice) && hasValue(maxPrice);

    const currentLabel = hasInitialValues
      ? maxPrice == 1
        ? intl.formatMessage(
            { id: 'GuestsFilter.labelSelectedButtonOne' },
            {
              maxPrice,
            }
          )
        : intl.formatMessage(
            { id: 'GuestsFilter.labelSelectedButton' },
            {
              maxPrice,
            }
          )
      : label
      ? label
      : intl.formatMessage({ id: 'PriceFilter.label' });

    const contentStyle = this.positionStyleForContent();

    const guestsLabel = initialValues?.guests ? initialValues.guests.split(',')[1] : false;
    return (
      <div className={classes}>
        <div className={css.filterHeader}>
          <button type="button" className={css.labelButton} /* onClick={this.toggleIsOpen} */>
            <span className={css.labelButtonContent}>
              <span className={css.labelWrapper}>
                <span className={css.label}>
                  {label}
                  {guestsLabel ? (
                    <>
                      <span>{': '}</span>
                      <span className={css.labelSelected}>{guestsLabel}</span>
                    </>
                  ) : null}
                </span>
              </span>
        {/*       <span className={css.openSign}>
                <IconPlus isOpen={!this.state.isFormOpen} isSelected={hasInitialValues} />
              </span> */}
            </span>
          </button>
        </div>
        <div className={css.formWrapper}>
       <GuestFilterForm
            id={id}
            initialValues={hasInitialValues ? initialPrice : { minPrice: min, maxPrice: max }}
            onClear={this.handleClear}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            onChange={this.handleSubmit}
            intl={intl}
            contentRef={node => {
              this.filterContent = node;
            }}
            style={contentStyle}
            min={min}
            max={max}
            step={step}
            showAsPopup={false}
            isOpen={!this.state.isFormOpen}
          >
          </GuestFilterForm>
        </div>
      </div>
    );
  }
}

GuestsFilterPopup.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
  liveEdit: false,
  step: number,
};

GuestsFilterPopup.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  queryParamNames: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    price: string,
  }),
  contentPlacementOffset: number,
  min: number.isRequired,
  max: number.isRequired,
  step: number,
  marketplaceCurrency: string.isRequired,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(GuestsFilterPopup);
