import React, { useEffect } from 'react';
import css from './AuthModal.module.css';
import Modal from '../Modal/Modal';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import SignupForm from '../../containers/AuthenticationPage/SignupForm/SignupForm';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { authenticationInProgress, login, signup, signupWithIdp } from '../../ducks/auth.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { useState } from 'react';
import LoginForm from '../../containers/AuthenticationPage/LoginForm/LoginForm';
//import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import TermsAndConditions from '../../containers/AuthenticationPage/TermsAndConditions/TermsAndConditions';
import { Form } from 'react-final-form';

const AuthModalComponent = props => {
  const [tab, setTab] = useState('login');
  const {
    isOpen,
    setOpen,
    isLogin,
    showFacebookLogin,
    showGoogleLogin,
    from,
    submitLogin,
    loginError,
    signupError,
    authInProgress,
    submitSignup,
    termsAndConditions,
    redirectToCheckout,
    currentUser,
  } = props;

  const [loginWrong, setLoginWrong] = useState('');
  const { isAuthenticated } = props;
  useEffect(() => {
    if (isAuthenticated && currentUser !== null) {
      setOpen(false);
      redirectToCheckout();
    }
  }, [currentUser]);
  const handleSubmitSignup = async values => {
    const { fname, lname, ...rest } = values;
    const params = { firstName: fname.trim(), lastName: lname.trim(), ...rest };
    await submitSignup(params);
    setOpen(false);
  };

  const handleLogin = async args => {
    await submitLogin(args);
    setLoginWrong(
      <div className={css.error}>
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </div>
    );
  };

  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setOpen(false);
      }}
      onManageDisableScrolling={() => {}}
    >
      <div className={css.wrapper}>
        <div className={css.tabsSwitcher}>
          <div
            onClick={() => setTab('login')}
            className={tab === 'login' ? css.tabSelected : css.tab}
          >
            <FormattedMessage id="LoginForm.logIn" />
          </div>
          <div
            onClick={() => setTab('signup')}
            className={tab !== 'login' ? css.tabSelected : css.tab}
          >
            <FormattedMessage id="SignupForm.signUp" />
          </div>
        </div>
          {tab === 'login' ? (
         <div className={css.formsWrapper}>
             <div className={css.error}>{loginWrong}</div>
            <LoginForm
              className={css.loginForm}
              onSubmit={handleLogin}
              inProgress={authInProgress}
            />
            </div>
          ) : (
            <div className={css.signupWrapper}>
            <div className={css.error}>{loginWrong}</div>
            <SignupForm
              className={css.signupForm}
              onSubmit={handleSubmitSignup}
              inProgress={authInProgress}
              termsAndConditions={
                <TermsAndConditions
                  onOpenTermsOfService={() => setTosModalOpen(true)}
                  onOpenPrivacyPolicy={() => setPrivacyModalOpen(true)}
                  intl={intl}
                />
              }
            />
            </div>
          )}
      </div>
    </Modal>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    pageAssetsData: privacyAssetsData,
    inProgress: privacyFetchInProgress,
    error: privacyFetchError,
  } = state.hostedAssets || {};
  const { pageAssetsData: tosAssetsData, inProgress: tosFetchInProgress, error: tosFetchError } =
    state.hostedAssets || {};

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    privacyAssetsData,
    privacyFetchInProgress,
    privacyFetchError,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
  };
};
const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const AuthModal = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthModalComponent);

export default AuthModal;
