import React from 'react';
import { bool } from 'prop-types';

import GuestsFilterPlain from './GuestsFilterPlain';
import GuestsFilterPopup from './GuestsFilterPopup';

const GuestsFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <GuestsFilterPopup {...rest} />
  ) : (
    <GuestsFilterPlain  {...rest} />
  );
};

GuestsFilter.defaultProps = {
  showAsPopup: false,
};

GuestsFilter.propTypes = {
  showAsPopup: bool,
};

export default GuestsFilter;
