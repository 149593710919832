import React, { Component } from 'react';
import { array, arrayOf, func, node, number, object, oneOf, string } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../../util/reactIntl';
import { parseSelectFilterOptions } from '../../../util/search';
import { SCHEMA_TYPE_ENUM, SCHEMA_TYPE_MULTI_ENUM } from '../../../util/types';

import { FieldCheckbox } from '../../../components';

import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';

import css from './SelectMultipleFilter.module.css';

import whitheSquare from '../../../assets/whithSquare.webp';
import singlebedroom from '../../../assets/icons/roomtypes/onebedroom.png';
import doublebedroom from '../../../assets/icons/roomtypes/doublebedroom.png';
import twobedroom from '../../../assets/icons/roomtypes/twobedroom.png';
import shared_bedroom from '../../../assets/icons/roomtypes/shared_bedroom.png';
import multi_bedroom from '../../../assets/icons/roomtypes/multi_bedroom.png';
import entire_accomodation from '../../../assets/icons/roomtypes/entire_accomodation.png';
import tent from '../../../assets/icons/roomtypes/camping.png';

import comfort from '../../../assets/icons/characteristics/Komfort.webp';
import adventure from '../../../assets/icons/characteristics/adventure.webp';
import nature from '../../../assets/icons/characteristics/Nature.webp';
import near_beach from '../../../assets/icons/characteristics/Beach.webp';
import animallovers from '../../../assets/icons/characteristics/Animals.webp';
import sportlovers from '../../../assets/icons/characteristics/Sport.webp';
import familyfriendly from '../../../assets/icons/characteristics/Family.webp';
import trekking from '../../../assets/icons/characteristics/Trekking.webp';
import culture from '../../../assets/icons/characteristics/Theatre.webp';
import barrier_free from '../../../assets/icons/characteristics/barrierefreiheit.webp';
import accessibility from '../../../assets/icons/characteristics/Bus.webp';
import projecttours_possible from '../../../assets/icons/characteristics/Projektbesuch_.webp';
import projecttours_impossible from '../../../assets/icons/characteristics/Projektbesuch_Cancel.webp';

import light from '../../../assets/icons/equipment/Lampe.webp';
import electricity from '../../../assets/icons/equipment/Electricity.webp';
import bedsheets from '../../../assets/icons/equipment/Bettwäsche_.webp';
import sink from '../../../assets/icons/equipment/Sink.webp';
import shower from '../../../assets/icons/equipment/Shower.webp';
import toilet from '../../../assets/icons/equipment/Toilet.webp';
import privat_bathroom from '../../../assets/icons/equipment/Bathroom.webp';
import shared_bathroom from '../../../assets/icons/equipment/Bathroom-Shared.webp';
import private_kitchen from '../../../assets/icons/equipment/Kitchen.webp';
import shared_kitchen from '../../../assets/icons/equipment/SharedKitchen.webp';
import internet from '../../../assets/icons/equipment/Wifi.webp';
import computer from '../../../assets/icons/equipment/Computer.webp';
import tv from '../../../assets/icons/equipment/Tv.webp';
import mosquito_net from '../../../assets/icons/equipment/Moskito.webp';
import towels from '../../../assets/icons/equipment/Handtücher_.webp';
import fan from '../../../assets/icons/equipment/Ventilator_.webp';
import air_conditioning from '../../../assets/icons/equipment/Klimaanlage.webp';
import heating from '../../../assets/icons/equipment/Heater.webp';
import safe from '../../../assets/icons/equipment/Security_Locker.webp';
import wardrobe from '../../../assets/icons/equipment/Dresser.webp';
import parking from '../../../assets/icons/equipment/Parking.webp';
import luggage_storage from '../../../assets/icons/equipment/Luggage.webp';
import laundry_facilities from '../../../assets/icons/equipment/Waschküche_.webp';

import supermarket from '../../../assets/icons/surroundings/Supermarket.png';
import restaurant from '../../../assets/icons/surroundings/Restaurant.png';
import facility from '../../../assets/icons/surroundings/Doctor.png';
import washing from '../../../assets/icons/equipment/Waschküche_.webp';
import bank from '../../../assets/icons/surroundings/Bank.png';
import tour from '../../../assets/icons/surroundings/Touranbieter.png';

import Bildung from '../../../assets/categories/education.webp';
import Naturschutz from '../../../assets/categories/environment.webp';
import Tierschutz from '../../../assets/categories/animal.webp';
import Equality from '../../../assets/categories/equality.webp';
import Health from '../../../assets/categories/health.webp';
import Sports from '../../../assets/categories/sports.webp';

import breakfast_inclusive from '../../../assets/icons/meals/Breakfast.webp';
import breakfast_for_sale from '../../../assets/icons/meals/Breakfast-paid.webp';
import lunch_inclusive from '../../../assets/icons/meals/Lunch.webp';
import lunch_for_sale from '../../../assets/icons/meals/Lunch-paid.webp';
import dinner_inclusive from '../../../assets/icons/meals/Abendessen.webp';
import dinner_for_sale from '../../../assets/icons/meals/Abendessen-paid.webp';
import vegetarian_options from '../../../assets/icons/meals/Vegetarian_.webp';
import vegan_options from '../../../assets/icons/meals/Vegan.webp';
import coffee_tee from '../../../assets/icons/meals/Coffee_.webp';

// SelectMultipleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
//       There's a mutation problem: formstate.dirty is not reliable with it.

const optionImageMap = {
  'B&B': whitheSquare,
  glamping: whitheSquare,
  hostel: whitheSquare,
  hotel: whitheSquare,
  homestay: whitheSquare,
  holiday: whitheSquare,
  apartment: whitheSquare,
  tinyHouse: whitheSquare,
  camping: whitheSquare,
  banglow: whitheSquare,

  singlebedroom: singlebedroom,
  doublebedroom: doublebedroom,
  twobedroom: twobedroom,
  shared_bedroom: shared_bedroom,
  multi_bedroom: multi_bedroom,
  entire_accomodation: entire_accomodation,
  tent: tent,

  comfort: comfort,
  adventure: adventure,
  nature: nature,
  near_beach: near_beach,
  animallovers: animallovers,
  sportlovers: sportlovers,
  familyfriendly: familyfriendly,
  trekking: trekking,
  culture: culture,
  barrier_free: barrier_free,
  accessibility: accessibility,
  projecttours_possible: projecttours_possible,
  projecttours_impossible: projecttours_impossible,

  light: light,
  electricity: electricity,
  bedsheets: bedsheets,
  sink: sink,
  shower: shower,
  toilet: toilet,
  privat_bathroom: privat_bathroom,
  shared_bathroom: shared_bathroom,
  private_kitchen: private_kitchen,
  shared_kitchen: shared_kitchen,
  internet: internet,
  computer: computer,
  tv: tv,
  mosquito_net: mosquito_net,
  towels: towels,
  fan: fan,
  air_conditioning: air_conditioning,
  heating: heating,
  safe: safe,
  wardrobe: wardrobe,
  parking: parking,
  luggage_storage: luggage_storage,
  laundry_facilities: laundry_facilities,

  supermarket: supermarket,
  restaurant: restaurant,
  facility: facility,
  washing: washing,
  bank: bank,
  public_transport: accessibility,
  tour: tour,

  english: whitheSquare,
  spanish: whitheSquare,
  french: whitheSquare,
  german: whitheSquare,
  portuguese: whitheSquare,
  chinese: whitheSquare,

  Bildung: Bildung,
  Naturschutz: Naturschutz,
  Tierschutz: Tierschutz,
  Equality: Equality,
  Health: Health,
  Sports: Sports,

  Dollar: whitheSquare,
  Euro: whitheSquare,

  breakfast_inclusive: breakfast_inclusive,
  breakfast_for_sale: breakfast_for_sale,
  lunch_inclusive: lunch_inclusive,
  lunch_for_sale: lunch_for_sale,
  dinner_inclusive: dinner_inclusive,
  dinner_for_sale: dinner_for_sale,
  vegetarian_options: vegetarian_options,
  vegan_options: vegan_options,
  coffee_tee: coffee_tee,

  // ... and so on for other options
};
const GroupOfFieldCheckboxes = props => {
  const { id, className, name, options } = props;
  return (
    <fieldset className={className}>
      <ul className={css.list}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox
                id={fieldId}
                icon={optionImageMap[option.key]}
                name={name}
                label={option.label}
                value={option.key}
              />
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const format = (selectedOptions, queryParamName, schemaType, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = schemaType === SCHEMA_TYPE_MULTI_ENUM && searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;
  return { [queryParamName]: value };
};

class SelectMultipleFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      options,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      schemaType,
      searchMode,
      intl,
      showAsPopup,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const queryParamName = getQueryParamName(queryParamNames);
    const hasInitialValues = !!initialValues && !!initialValues[queryParamName];
    // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
    const selectedOptions = hasInitialValues
      ? parseSelectFilterOptions(initialValues[queryParamName])
      : [];

    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilter.labelSelected' },
          { labelText: label, count: selectedOptions.length }
        )
      : label;

    const labelSelectionForPlain = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilterPlainForm.labelSelected' },
          { count: selectedOptions.length }
        )
      : '';

    const contentStyle = this.positionStyleForContent();

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: selectedOptions };

    const handleSubmit = values => {
      const usedValue = values ? values[name] : values;
      onSubmit(format(usedValue, queryParamName, schemaType, searchMode));
    };

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <GroupOfFieldCheckboxes
          className={css.fieldGroup}
          name={name}
          id={`${id}-checkbox-group`}
          options={options}
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={label}
        labelSelection={labelSelectionForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        {...rest}
      >
        <GroupOfFieldCheckboxes
          className={css.fieldGroupPlain}
          name={name}
          id={`${id}-checkbox-group`}
          options={options}
        />
      </FilterPlain>
    );
  }
}

SelectMultipleFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
  searchMode: null,
};

SelectMultipleFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  searchMode: oneOf(['has_all', 'has_any']),
  schemaType: oneOf([SCHEMA_TYPE_ENUM, SCHEMA_TYPE_MULTI_ENUM]).isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SelectMultipleFilter);
